<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t('searchArea.schDiv') }}</span> :
           </div>
           <select v-model="searchOption.keyword">
             <option value="">{{ $t('searchArea.total') }}</option>
             <option value="ip">{{ $t('table.head.ip') }}</option>
             <option value="regId">{{ $t('table.head.addmaster') }}</option>
             <option value="adminId">{{ $t('table.head.applyId') }}</option>
           </select>
         </div>
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('common.keyword') }}</span> :</div>
           <input type="text" v-model="searchOption.text" />
         </div>
         <button class="btn-search" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
         <button type="button" class="btn-layout btn-blue ml-5" @click="searchReset">{{ $t('button.resetSearch') }}</button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <div class="total-info-wrap">
          <!-- <button type="button" class="btn-layout btn-grey mr-5">테스트 메세지 추가</button> -->
          <button type="button" class="btn-layout btn-grey" @click="regModalToggle()">{{ $t('button.add') }}</button>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col width="16.66%"/>
            <col width="16.66%"/>
            <col width="16.66%"/>
            <col width="16.66%"/>
            <col width="16.66%"/>
            <col width="16.66%"/>
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="dataLength !== 0">
              <tr v-for="item in tableData" :key="item.ip">
                <td>{{ item.ip }}</td>
                <td>{{ item.adminId }}</td>
				        <td>{{ item.regId }}</td>
                <td>{{ item.regDate }}</td>
				        <td>{{ item.updDate }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="accessIpDel(item)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="regModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('common.addIp') }}</h3>
          </div>
          <div class="page-content">
             <div class="item-row-container">
               <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.appliedID') }}</div>
                <div class="item-content">
                   <input type="text" ref="regId" v-model="reqData.adminId" />
                </div>
               </div>
             </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.allowedip') }}</div>
                <div class="item-content">
                  <input type="text" ref="regIp" v-model="reqData.ip" />
                </div>
              </div>
            </div>

            <div class="modal-btns">
              <a @click="accessIpSave">{{ $t('button.save') }}</a>
              <a @click="regModalToggle">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>

  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { getCode } from '@/api/common'
import { adminList, adminIp, adminIpSave } from '@/api/admin'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'

export default {
  name: 'userList_ip',
  components: {
    TableHead,
    Pagination,
    DateSelector
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['regIp', 'applyId', 'addmasterid', 'addday', 'updDate2', 'option']
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        ip: '',
        regId: '',
        adminId: '',
        siteId: ''
      },
      saveData: {
        adminId: '',
        adminPass: '',
        adminGroup: 'submaster'
      },
      searchOption: {
        keyword: '',
        text: ''
      },
      siteInfo: {},
      pageInfo: {},
      totalInfo: {},
      regModalActive: false,
      tableData: [],
      dataLength: 0
    }
  },
  methods: {
    searchReset () {
      this.searchOption.keyword = ''
      this.searchOption.text = ''
    },
    pageSeach () {
      const searchOptions = this.searchOption

      if (searchOptions.keyword) {
        this.reqData[searchOptions.keyword] = searchOptions.text
        this.setTableData(1)
      } else {
        this.reqData = {
          ...this.reqData,
          ip: '',
          regId: '',
          adminId: ''
        }
      }
      this.setTableData(1)
    },
    regModalToggle () {
      this.regModalActive = !this.regModalActive
    },
    async adminCheck (adminId) {
      const req = {
        adminId
      }
      const res = await adminList(req)
      console.log(res)
      const exist = res.data.list.length
      if (exist === 0) {
        return false
      } else {
        return true
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async accessIpSave () {
      const regIpData = lodash.cloneDeep(this.reqData)

      if (!regIpData.ip) {
        this.$refs.regIp.focus()
        alert('IP를 입력해주세요.')
        return
      }
      if (!regIpData.adminId) {
        this.$refs.regId.focus()
        alert('관리자를 입력해주세요.')
        return
      }

      // console.log('regIpData.adminId : ', regIpData.adminId)
      // const idOverlap = await this.adminCheck(regIpData.adminId)
      // if (!idOverlap) {
      //   alert('존재하지 않는 관리자 입니다.')
      //   return
      // }

      regIpData.regId = this.siteInfo.siteId
      regIpData.siteId = this.siteInfo.siteId

      const res = await adminIpSave(regIpData)
      if (res.resultCode === '0') {
        alert('등록 완료')
        for (const key in this.regIpData) {
          this.regIpData[key] = ''
        }
        this.regModalToggle()
        await this.setTableData(1)
      } else {
        alert('등록 실패, 다시 시도해주세요.')
      }
    },
    async accessIpDel (item) {
      const req = {
        adminId: item.adminId,
        ip: item.ip,
        delYn: 'Y'
      }
      const res = await adminIpSave(req)
      if (res.resultCode === '0') {
        alert('삭제 완료')
        this.setTableData(1)
      } else {
        alert('삭제 실패, 다시 시도해주세요.')
      }
    },
    async getIpList (page, search) {
      if (page) {
        this.reqData.page = page
      }
      let req = lodash.cloneDeep(this.reqData)
      if (search) {
        req = search
      }
      const res = await adminIp(req)
      return res.data
    },
    async setTableData (page) {
      this.emitter.emit('Loading', true)
      const data = await this.getIpList(page)
      this.emitter.emit('Loading', false)
      for (const item of data.list) {
        item.regDate = replaceDateT(item.regDate)
        item.updDate = replaceDateT(item.updDate)
      }
      this.tableData = data.list
      this.dataLength = this.tableData.length
      if (this.tableData.length !== 0) {
        this.pageInfo = data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.sub-title {
  font-size: 1em;
  font-weight: 700;
  color: var(--main);
  height: 32px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  font-size: 0.9em;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.pt30 {
  padding-top: 30px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.modal-wrap {
  min-width: unset;
  width: unset;
}
.item-wrap > div.item-title {
  min-width: 100px;
}
.page-contents-wrap {
  margin-bottom: 0;
}
.page-content {
  padding-bottom: 30px;
}
</style>
